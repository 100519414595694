import React from 'react'
import { Helmet } from 'react-helmet'
import HandsOnHelpContent from 'componentsNew/HandsOnHelp'
import LayoutNew from 'componentsNew/LayoutNew'

const phone = '866-430-8512'

const HandsOnHelp = ({ location }) => {
  return (
    <LayoutNew location={location} includeCitySearch>
      <Helmet>
        <title>
          Talk to a Medicare Expert in your Area with our Hands on Help | Medicare
          Companion
        </title>
      </Helmet>
      <HandsOnHelpContent phone={phone} formPortal={1} />
    </LayoutNew>
  )
}

export default HandsOnHelp
